import { GetServerSideProps } from "next";
import { SectionHeading } from "~/components/SectionHeading";
import { Categories } from "~/components/Categories";
import {
  StrapiFrontPage,
  StrapiMainCategory,
  StrapiProduct,
  StrapiStore,
} from "~/shared-types";
import { findRandomStores, findSingleType } from "~/strapi/api";
import { ExploreSection } from "./ExploreSection";
import { FeaturedProductsSection } from "./FeaturedProductsSection";
import { handleError, handleRequestError } from "~/utils/rollbar";
import { NewStoresSection } from "./NewStoresSection";
import { useProductsQuery } from "~/api/products";
import { useSingleStoreAtom } from "~/atoms/singleStore";
import { useEffect, useState } from "react";
import { useStoresQuery } from "~/api/stores";
import { randomSort } from "~/utils/randomSort";
import { useMainCategoriesQuery } from "~/api/mainCategories";

type ServerSideProps = {
  page: StrapiFrontPage;
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
  context
) => {
  const page = await findSingleType<StrapiFrontPage>("frontpage").catch(
    handleRequestError(context.req, null)
  );
  if (!page) return { notFound: true };

  return { props: { page } };
};

export default function Home({ page }: ServerSideProps) {
  const {
    welcomeTitle,
    welcomeSubtitle,
    exploreTitle,
    exploreSubtitle,
    exploreMediaBackground,
    featuredProductsTitle,
    featuredProductsSubtitle,
    newStoresTitle,
    newStoresSubtitle,
  } = page.attributes;
  const [randomStore, setRandomStore] = useState<StrapiStore | null>(null);

  const { onVisitDifferentStore } = useSingleStoreAtom();
  useEffect(() => {
    onVisitDifferentStore(null);
  }, []);

  const { data: newStoresData, isLoading: isLoadingNewStores } = useStoresQuery(
    { sortKey: "id", sortDirection: "desc" }
  );
  const newStores = newStoresData?.pages
    .flatMap((page) => page?.data)
    .filter(Boolean) as StrapiStore[];

  const { data: featuredProductsData, isLoading: isLoadingFeaturesProducts } =
    useProductsQuery({ sortKey: "isFeatured", sortDirection: "desc" });
  let featuredProducts = featuredProductsData?.pages
    .flatMap((page) => page?.data)
    .filter(Boolean) as StrapiProduct[];

  const { data: mainCategoriesData, isLoading: isLoadingMainCategories } =
    useMainCategoriesQuery();
  const mainCategories = mainCategoriesData?.pages
    .flatMap((page) => page?.data)
    .filter(Boolean) as StrapiMainCategory[];

  // Shuffle the order, but make sure that featured products are first
  const actualFeaturedProducts = featuredProducts.filter(
    (product) => product.attributes.isFeatured
  );
  const otherFeaturedProducts = featuredProducts.filter(
    (product) => !product.attributes.isFeatured
  );

  featuredProducts = [
    ...randomSort(actualFeaturedProducts),
    ...randomSort(otherFeaturedProducts),
  ];

  useEffect(() => {
    findRandomStores(1)
      .then((data) => {
        setRandomStore(data[0]);
      })
      .catch((e) => {
        handleError(e);
        setRandomStore(null);
      });
  }, []);

  return (
    <>
      <SectionHeading>
        <SectionHeading.Title className="mb-5">
          {welcomeTitle}
        </SectionHeading.Title>
        <SectionHeading.Subtitle className="mb-20">
          {welcomeSubtitle}
        </SectionHeading.Subtitle>
      </SectionHeading>
      <Categories
        isLoading={isLoadingMainCategories}
        categories={mainCategories}
        className="mb-20"
      />
      <ExploreSection
        title={exploreTitle}
        subtitle={exploreSubtitle}
        media={exploreMediaBackground.data}
        store={randomStore}
      />
      <FeaturedProductsSection
        title={featuredProductsTitle}
        subtitle={featuredProductsSubtitle}
        products={featuredProducts}
        className="mb-20"
        isLoading={isLoadingFeaturesProducts}
      />
      <NewStoresSection
        title={newStoresTitle}
        subtitle={newStoresSubtitle}
        stores={newStores}
        className="mb-20"
        isLoading={isLoadingNewStores}
      />
    </>
  );
}
