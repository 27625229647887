import { CardSkeleton } from "../Card";

import "swiper/swiper.min.css";
import "swiper/css/a11y";
import { Slider } from "../Slider";
import { StrapiProduct } from "~/shared-types";
import { ProductCard } from "../ProductCard";

type ProductsSliderProps = {
  products: StrapiProduct[];
  className?: string;
  isLoading?: boolean;
};

const getProductSlide = (product: StrapiProduct) => {
  return {
    id: product.id,
    component: <ProductCard product={product} />,
  };
};

function ProductsSlider({
  products,
  className,
  isLoading,
}: ProductsSliderProps) {
  if (isLoading) {
    return (
      <Slider
        elements={[
          { id: 1, component: <CardSkeleton /> },
          { id: 2, component: <CardSkeleton /> },
          { id: 3, component: <CardSkeleton /> },
          { id: 4, component: <CardSkeleton /> },
          { id: 5, component: <CardSkeleton /> },
        ]}
      />
    );
  }

  return (
    <div className={className}>
      <Slider elements={products.map(getProductSlide)} />
    </div>
  );
}

export { ProductsSlider };
