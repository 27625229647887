import classNames from "classnames";
import { Card } from "~/components/Card";
import { SectionHeading } from "~/components/SectionHeading";
import { Slider } from "~/components/Slider";
import { Spinner } from "~/components/Spinner";
import { StrapiStore } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getRoute } from "~/utils/getRoute";

type NewStoresSectionProps = {
  title: string;
  subtitle: string;
  stores: StrapiStore[];
  className?: string;
  isLoading?: boolean;
};

export const NewStoresSection = ({
  title,
  subtitle,
  stores,
  className,
  isLoading,
}: NewStoresSectionProps) => {
  // show newest store first
  stores.sort((a, b) => b.id - a.id);

  if (!isLoading && !stores.length) return null;

  const storeElements = isLoading
    ? [
        { id: 1, component: <StoreCardSkeleton /> },
        { id: 2, component: <StoreCardSkeleton /> },
        { id: 3, component: <StoreCardSkeleton /> },
        { id: 4, component: <StoreCardSkeleton /> },
        { id: 5, component: <StoreCardSkeleton /> },
      ]
    : stores.map((store) => ({
        id: store.id,
        component: <StoreCard store={store} />,
      }));

  return (
    <div className={classNames(className)}>
      <SectionHeading>
        <SectionHeading.Title className="mb-5">{title}</SectionHeading.Title>
        <SectionHeading.Subtitle className="mb-20">
          {subtitle}
        </SectionHeading.Subtitle>
      </SectionHeading>
      <Slider slideClass="aspect-square" elements={storeElements} />
    </div>
  );
};

type StoreCardProps = {
  store: StrapiStore;
};

const StoreCard = ({ store }: StoreCardProps) => {
  const { attributes } = store;
  const { storeImage, name, shownAddress } = attributes;
  const imageAttributes = storeImage.data?.attributes;

  return (
    <Card
      className="aspect-square"
      href={getRoute("/stores/:id", { id: store.id })}
    >
      {imageAttributes ? (
        <Card.Image
          className="h-full w-full object-cover rounded-xl"
          {...getFormattedImageAttributes(storeImage.data, "large")}
        />
      ) : (
        <div className="h-full rounded-xl bg-secondary-100 mb-3" />
      )}
      {shownAddress?.city && <Card.Location>{shownAddress.city}</Card.Location>}
      <Card.Title>{name}</Card.Title>
    </Card>
  );
};

const StoreCardSkeleton = () => (
  <div className="bg-primary-50 flex items-center justify-center aspect-square rounded-xl">
    <Spinner />
  </div>
);
