import { useInfiniteQuery } from "@tanstack/react-query";
import QueryString from "qs";
import { StrapiMainCategory, StrapiResponse } from "~/shared-types";
import { strapiClient as defaultStrapiClient } from "~/strapi/api";
import {
  QueryFunctionOptions,
  getNextPageParam,
  getQueryObject,
} from "./shared";

export const MAIN_CATEGORIES_QUERY_KEY = "mainCategories";

export const getMainCategories = async (options: QueryFunctionOptions = {}) => {
  const { strapiClient = defaultStrapiClient } = options;

  const queryObject = getQueryObject(options);
  const extendedQueryObject = {
    ...queryObject,
    populate: ["image", "icon"],
  };
  const query = QueryString.stringify(extendedQueryObject);

  const { data } = await strapiClient.get<StrapiResponse<StrapiMainCategory[]>>(
    "/main-categories?" + query
  );

  return data;
};

export const useMainCategoriesQuery = (
  options: QueryFunctionOptions = {},
  initialData?: StrapiResponse<StrapiMainCategory[]>
) => {
  const queryObject = getQueryObject(options);
  const query = QueryString.stringify(queryObject);

  return useInfiniteQuery({
    queryKey: [MAIN_CATEGORIES_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) =>
      getMainCategories({ ...options, pageParam }),
    getNextPageParam,
    initialPageParam: initialData ? 1 : undefined,
    initialData: {
      pages: [initialData],
      pageParams: [initialData ? 1 : undefined],
    },
  });
};
