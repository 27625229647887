import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getRoute } from "~/utils/getRoute";
import { CategoriesProps, CategoryProps } from "./types";
import { Spinner } from "../Spinner";

const Categories = ({ categories, className, isLoading }: CategoriesProps) => {
  if (isLoading || categories.length === 0) {
    return (
      <div
        className={classNames(
          "grid grid-cols-2 md:grid-cols-4 gap-5",
          className
        )}
      >
        <CategorySkeleton />
        <CategorySkeleton />
        <CategorySkeleton />
        <CategorySkeleton />

        <CategorySkeleton />
        <CategorySkeleton />
        <CategorySkeleton />
        <CategorySkeleton />
      </div>
    );
  }

  return (
    <div
      className={classNames("grid grid-cols-2 md:grid-cols-4 gap-5", className)}
    >
      {categories.map((category) => (
        <Category key={category.id} category={category} />
      ))}
    </div>
  );
};

function Category({ category, className }: CategoryProps) {
  const {
    attributes: { name, image },
  } = category;

  return (
    <Link
      href={getRoute("/categories/:categoryId/:categoryName", {
        categoryId: category.id,
        categoryName: name,
      })}
      className={className}
    >
      <div>
        <Image
          {...getFormattedImageAttributes(image.data, "medium", {
            altFallback: name,
          })}
          className="aspect-video rounded-xl object-cover object-center mb-2 lg:mb-5 w-full"
          priority
        />
        <h3 className="text-xl">{name}</h3>
      </div>
    </Link>
  );
}

function CategorySkeleton() {
  return (
    <div className="aspect-video rounded-xl bg-primary-50 flex items-center justify-center">
      <Spinner />
    </div>
  );
}

export { Categories, Category };
