import { useInfiniteQuery } from "@tanstack/react-query";
import QueryString from "qs";
import { StrapiResponse, StrapiStore } from "~/shared-types";
import { strapiClient } from "~/strapi/api";
import { Options, getNextPageParam, getQueryObject } from "./shared";

export const STORES_QUERY_KEY = "stores";

export async function getStores(options: Options = {}) {
  const queryObject = getQueryObject({
    ...options,
    populate: [...(options.populate ?? []), "storeImage"],
  });
  const query = QueryString.stringify(queryObject);
  const { data } = await strapiClient.get<StrapiResponse<StrapiStore[]>>(
    `/stores?${query}`
  );

  return data;
}

export const useStoresQuery = (
  options: Options = {},
  initialData?: StrapiResponse<StrapiStore[]>
) => {
  const queryObject = getQueryObject(options);
  const query = QueryString.stringify(queryObject);

  const stores = useInfiniteQuery({
    queryKey: [STORES_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) => getStores({ ...options, pageParam }),
    getNextPageParam,
    initialPageParam: initialData ? 1 : undefined,
    initialData: {
      pages: [initialData],
      pageParams: [initialData ? 1 : undefined],
    },
  });

  return stores;
};
